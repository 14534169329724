import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { setNotice } from '../../redux/noticesSlice';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const id = String(new Date().getTime());
    const isRead = false;
    api.dispatch(
      setNotice({
        ...action.payload.data,
        id,
        isRead,
        ...(action.payload.data.statusCode === 404 && {
          timestamp: new Date().toISOString(),
          message: `Не найден адрес: ${action.payload.data?.message.split(' ')?.[2]}`,
        }),
        //TODO: после отладки скрыть сообщение о 403 ошибке 
        ...(action.payload.data.statusCode === 403 && {
          timestamp: new Date().toISOString(),
          message: `Доступ запрещён: ${action.payload.data?.path}`,
        }),
      }),
    );
  }

  return next(action);
};
