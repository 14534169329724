import React, { FC, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as AppIcon } from '../../icons/App.svg';
import styles from './DeleteProfile.module.css';
import { TUser, useLazyGetUsersQuery } from '../../redux/services/client';
import { BACKEND_URL, CLIENT_ID, PROJECT_NAME } from '../../constants';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useDeleteUsersInfoMutation, useDeleteUsersMutation } from '../../redux/services/owner';
import { CustomButton } from '../custom/CustomButton';
import { useDispatch } from 'react-redux';
import { setNotice } from '../../redux/noticesSlice';

export const DeleteProfileAsAdmin: FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [deleteOption, setDeleteOption] = useState('account');

  const [getUsers] = useLazyGetUsersQuery();
  const [deleteUsers, deleteResult ] = useDeleteUsersMutation();
  
  useEffect(() => {
    if (deleteResult.isSuccess && deleteResult.data?.errors.length) {
      dispatch(
        setNotice({
          error: 'Ошибка',
          id: Math.random(),
          isRead: false,
          message: deleteResult.data?.errors[0],
          timestamp: new Date().toString(),
        }),
      );
    }
  }, [deleteResult]);

  const { clientId, userId } = useParams<{ clientId: string; userId: string }>();
  const [selectedUser, setSelectedUser] = useState<
    { user: Partial<TUser>; role: string } | null | undefined
  >(null);
  const [getDeleteUsersInfo, { data: deleteUsersInfoData, isLoading: deleteUsersInfoLoading }] =
    useDeleteUsersInfoMutation();
  const applicationsToDelete =
    deleteUsersInfoData?.apps.filter((app) => app.onlyEditor).map((app) => app.client) || [];

  useEffect(() => {
    const start = async () => {
      const { data: users } = await getUsers({
        client_id: clientId || '',
        number_of_skip: '0',
        sort_direction: 'asc',
        search_string: '',
        search_param_user_id: userId,
      });

      setSelectedUser(users?.find((user) => user.user.id === Number(userId)));
    };
    start();
  }, [clientId, userId]);

  useEffect(() => {
    const onMount = async () => {
      try {
        await getDeleteUsersInfo({ checked_ids: [parseInt(userId, 10)] }).unwrap();
      } catch (e) {
        console.log('onMount error:', e);
      }
    };
    onMount();
  }, [userId]);

  const handleDelete = async () => {
    try {
      if (userId && deleteOption === 'account') {
        await deleteUsers({
          checked_ids: [parseInt(userId, 10)],
          apps_ids: deleteUsersInfoData?.apps
            .filter((app) => !app.onlyEditor)
            .map(({ client }) => client.client_id),
          });
      } else if (userId && deleteOption === 'accountAndApplicationsWithUsers') {
        await deleteUsers({
          checked_ids: [parseInt(userId, 10)],
          apps_ids: deleteUsersInfoData?.apps.map(({ client }) => client.client_id),
        });
      }
      if (clientId === CLIENT_ID) history.push('/users');
      else history.push(`/application/${clientId}`);
    } catch (e) {
      console.log('handleDelete error:', e);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeleteOption((event.target as HTMLInputElement).value);
  };

  const applicationsComponent = () =>
    applicationsToDelete?.map((app) => (
      <div key={app.client_id} className={styles.app}>
        <div
          style={{
            backgroundImage: `url(${BACKEND_URL + '/' + app.avatar})`,
          }}
          className={styles['app-icon']}
        >
          {!app.avatar && <AppIcon fill="#ced0d9" />}
        </div>
        <Link className={styles['app-link']} to={`/application/${app.client_id}`}>
          {app.name}
        </Link>
      </div>
    ));
  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => history.push(`/application/user/${clientId}/${userId}`)}
        className={clsx('color-4C6AD4', 'text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['delete-account']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          Удалить аккаунт {PROJECT_NAME}
        </Typography>
        <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
          Аккаунт <strong>{selectedUser?.user.nickname}</strong> будет удален. Данные, связанные с
          аккаунтом, удалятся навсегда. Пользователь потеряет доступ к приложениям, в которых для
          входа использовал аккаунт {PROJECT_NAME}.
        </Typography>
        {!!deleteUsersInfoData?.apps.length && (
          <>
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Вместе с аккаунтом будут удалены приложения без участников, в которых удаляемый
              пользователь является единственным администратором.
            </Typography>
            {!!applicationsToDelete.length && (
              <>
                <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
                  Выберите, что нужно сделать с приложениями с участниками, в которых удаляемый
                  пользователь является единственным администратором:
                </Typography>
                <FormControl style={{ marginBottom: 24 }}>
                  <RadioGroup
                    aria-labelledby="controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={deleteOption}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="account"
                      control={
                        <Radio
                          sx={{
                            color: '#0B1641',
                            '&.Mui-checked': {
                              color: '#0B1641',
                            },
                          }}
                        />
                      }
                      label="Удалить только аккаунт"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '14px',
                          color: '#0B1641',
                          lineHeight: '21px',
                        },
                      }}
                    />
                    <FormControlLabel
                      value="accountAndApplicationsWithUsers"
                      control={
                        <Radio
                          sx={{
                            color: '#0B1641',
                            '&.Mui-checked': {
                              color: '#0B1641',
                            },
                          }}
                        />
                      }
                      label="Удалить аккаунт и все приложения, которыми он управляет"
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontSize: '14px',
                          color: '#0B1641',
                          lineHeight: '21px',
                        },
                      }}
                    />
                  </RadioGroup>
                </FormControl>
                <div className={styles['apps-wrapper']} style={{ marginBottom: 8 }}>
                  {applicationsComponent()}
                </div>
              </>
            )}
          </>
        )}
        <div style={{ marginTop: 32, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => history.goBack()} variant="custom" color="secondary">
            Отмена
          </Button>
          <CustomButton
            loading={deleteUsersInfoLoading}
            disabled={deleteUsersInfoLoading}
            onClick={handleDelete}
            variant="custom"
            style={{ marginLeft: 24, background: '#FC545C' }}
          >
            Удалить аккаунт
          </CustomButton>
        </div>
      </div>
    </div>
  );
};
