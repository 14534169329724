import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './components/custom/Theme';
import { Code } from './components/Code';
import Box from '@mui/material/Box';
import styles from './App.module.css';
import { TopBar } from './components/TopBar';
import { LeftMenu } from './components/LeftMenu';
import { PrivateRoute } from './components/routes/PrivateRoute';
import { Login } from './components/Login';
import { getUserProfile } from './requests/oidc';
import { convertUserProfile, isOwner } from './helpers';
import { getUserRoleInApp } from './requests/user';
import { CLIENT_ID } from './constants';
import { setUserProfile } from './redux/userSlice';
import { RootState } from './redux/store';
import clsx from 'clsx';
import { setIsMenuOpen, setIsMobile, TAppSlice } from './redux/appSlice';
import { setNotice, TNoticeSlice } from './redux/noticesSlice';
import { Applications } from './components/applications/Applications';
import { ApplicationInfo } from './components/applications/ApplicationInfo';
import { CreateApplication } from './components/applications/CreateApplication';
import { EditProfile } from './components/profile/EditProfile';
import { UserProfile } from './components/profile/UserProfile';
import { DeleteProfile } from './components/profile/DeleteProfile';
import { ChangePassword } from './components/profile/ChangePassword';
import { ConfirmEmail } from './components/profile/ConfirmEmail';
import { NotificationPanel } from './components/NotificationPanel';
import { ProviderProfile } from './components/profile/ProviderProfile';
import { FillProfile } from './components/profile/FillProfile';
import { ApplicationUserInfo } from './components/applications/ApplicationUserInfo';
import { EventLog } from './components/EventLog';
import { BindLDAPAccount } from './components/profile/BindLDAPAccount';
import { Roles } from './enums';
import { RestoreProfile } from './components/profile/RestoreProfile';
import { ProviderUserProfile } from './components/profile/ProviderUserProfile';
import { ConfirmUserEmail } from './components/profile/ConfirmUserEmail';
import { EditProfileAsAdmin } from './components/profile/EditProfileAsAdmin';
import { OwnerOrEditorRoute } from './components/routes/OwnerOrEditorRoute';
import { OwnerRoute } from './components/routes/OwnerRoute';
import { useGetAvailableUsersCountQuery } from './redux/services/owner';
import { DeleteProfileAsAdmin } from './components/profile/DeleteProfileAsAdmin';
import { ESnackBarVariant } from './components/custom/CustomSnackbar';
import { UsersControl } from './components/users/UsersControl';
import { Error } from './components/Error';
import { EditApplicationWrapper } from './components/applications/EditApplicationWrapper';

type TAppProps = {
  isMenuOpen: boolean;
  isNotificationPanelOpen: TAppSlice['isNotificationPanelOpen'];
  isSnackbarOpen: TAppSlice['isSnackbarOpen'];
  notices?: Array<TNoticeSlice>;
  role?: Roles;
};

const mapStateToProps = (state: RootState) => ({
  isMenuOpen: state.app.isMenuOpen,
  isNotificationPanelOpen: state.app.isNotificationPanelOpen,
  isSnackbarOpen: state.app.isSnackbarOpen,
  notices: state.notices,
  role: state.user.userProfile.role,
});

const AppComponent: React.FC<TAppProps> = ({
  isMenuOpen,
  isNotificationPanelOpen,
  isSnackbarOpen,
  notices,
  role,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { data: availableUsersCount } = useGetAvailableUsersCountQuery(undefined, {
    skip: !isOwner(role),
  });

  useEffect(() => {
    const onResize = () => {
      dispatch(setIsMobile(window.innerWidth < 1024));
      if (window.innerWidth < 1024 && isMenuOpen) {
        dispatch(setIsMenuOpen(false));
      }
    };
    onResize();
    window.addEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    if (availableUsersCount !== undefined && availableUsersCount < 100) {
      dispatch(
        setNotice({
          error: 'Ошибка',
          id: Math.random(),
          isRead: false,
          message:
            availableUsersCount <= 0
              ? 'Места для регистрации пользователей закончились.'
              : `Заканчиваются места для регистрации пользователей (осталось: ${availableUsersCount}).`,
          timestamp: new Date().toString(),
        }),
      );
    }
  }, [availableUsersCount]);

  useEffect(() => {
    const getProfile = async () => {
      const profile = await getUserProfile();
      if (profile) {
        const convertedProfile = convertUserProfile(profile);
        const role = await getUserRoleInApp(convertedProfile.id || '', CLIENT_ID);
        dispatch(setUserProfile({ ...convertedProfile, role }));
      }
    };

    getProfile();
  }, []);

  useEffect(() => {
    if (!isNotificationPanelOpen && !isSnackbarOpen) {
      const firstItem = notices?.[0];
      if (typeof firstItem?.message === 'string')
        enqueueSnackbar(firstItem?.message, {
          variant: 'customSnackbar',
          avatar: firstItem?.avatar,
          snackbarVariant: firstItem.error ? ESnackBarVariant.error : ESnackBarVariant.notification,
        });
    }
  }, [notices?.length]);

  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <BrowserRouter>
          <Box className={styles.wrapper}>
            <TopBar />
            <LeftMenu />
            <Box className={clsx(styles.content, { [styles['menu-open']]: isMenuOpen })}>
              <Switch>
                <Route exact path="/login" children={<Login />} />
                <Route exact path="/code" children={<Code />} />
                <Route exact path="/error/:text" children={<Error />} />
                <PrivateRoute exact path="/profile" children={<UserProfile />} />
                <PrivateRoute exact path="/profile/edit" children={<EditProfile />} />
                <PrivateRoute exact path="/profile/delete" children={<DeleteProfile />} />
                <PrivateRoute exact path="/profile/change-password" children={<ChangePassword />} />
                <PrivateRoute path="/fill-profile" children={<FillProfile />} />
                <PrivateRoute path="/restore-profile" children={<RestoreProfile />} />
                <PrivateRoute exact path="/profile/email/:action" children={<ConfirmEmail />} />
                <PrivateRoute
                  exact
                  path="/profile/ldap/add/:provider_id"
                  children={<BindLDAPAccount />}
                />
                <PrivateRoute
                  exact
                  path="/profile/external-provider"
                  children={<ProviderProfile />}
                />
                <PrivateRoute
                  exact
                  path="/application/:applicationId"
                  children={<ApplicationInfo />}
                />
                <OwnerRoute exact path="/users" children={<UsersControl />} />
                <OwnerRoute
                  exact
                  path="/application/user/edit/:clientId/:userId"
                  children={<EditProfileAsAdmin />}
                />
                <OwnerRoute
                  exact
                  path="/application/user/delete/:clientId/:userId"
                  children={<DeleteProfileAsAdmin />}
                />
                <OwnerRoute
                  exact
                  path="/application/user/external-provider/:clientId/:userId"
                  children={<ProviderUserProfile />}
                />
                <OwnerRoute
                  exact
                  path="/application/user/email/:action/:clientId/:userId"
                  children={<ConfirmUserEmail />}
                />
                <OwnerOrEditorRoute
                  exact
                  path="/application/user/:clientId/:userId"
                  children={<ApplicationUserInfo />}
                />
                <PrivateRoute exact path="/applications" children={<Applications />} />
                <PrivateRoute exact path="/applications/create" children={<CreateApplication />} />
                <PrivateRoute
                  exact
                  path="/applications/edit/:clientId/:openedFromMenu?"
                  children={<EditApplicationWrapper />}
                />
                <PrivateRoute exact path="/event-log" children={<EventLog />} />
                <Redirect to="/profile" />
                {/* {NODE_ENV === 'development' && <Route path="/gallery" children={<Gallery />} />} */}
              </Switch>
            </Box>
            <NotificationPanel />
          </Box>
        </BrowserRouter>
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export const App = connect(mapStateToProps)(AppComponent);
