import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useGetApplicationByIdQuery } from '../../redux/services/client';
import { RootState } from '../../redux/store';
import { connect } from 'react-redux';
import { EditSettings } from './EditSettings';
import { CLIENT_ID } from '../../constants';
import { EditApplication } from './EditApplication';

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  userRole: state.user.userProfile.role,
});

const EditComponentWrapperComponent: FC<{ userId?: string }> = ({ userId }) => {
  const { clientId } = useParams<{ clientId: string }>();
  const { data: selectedClient } = useGetApplicationByIdQuery(
    { user_id: userId || '', client_id: clientId || '' },
    {
      skip: !userId || !clientId,
    },
  );
  return (
    <>
      {selectedClient && userId ? (
        clientId === CLIENT_ID ? (
          <EditSettings
            selectedClient={selectedClient.client}
            role={selectedClient.role}
            userId={userId}
          />
        ) : (
          <EditApplication
            selectedClient={selectedClient.client}
            role={selectedClient.role}
            userId={userId}
          />
        )
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export const EditApplicationWrapper = connect(mapStateToProps)(EditComponentWrapperComponent);
