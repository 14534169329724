import styles from './Applications.module.css';
import React, { FC, MouseEvent, ReactNode, SyntheticEvent, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as AddIcon } from '../../icons/Add.svg';
import clsx from 'clsx';
import { RootState } from '../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { isAdministrator } from '../../helpers';
import { ApplicationsTable } from './ApplicationsTable';
import { useHistory, useLocation } from 'react-router-dom';
import { ApplicationRightPanel } from './ApplicationRightPanel';
import { SelectViewType } from '../custom/SelectViewType';
import { setApplicationsTab, setClientPanel, TAppSlice } from '../../redux/appSlice';


interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return <div hidden={value !== index}>{value === index && <>{children}</>}</div>;
}

type TApplicationsProps = {
  userId?: string;
  userRole?: string;
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  isMobile: TAppSlice['isMobile'];
  applicationsTab: number;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  userRole: state.user.userProfile.role,
  isClientPanelOpen: state.app.isClientPanelOpen,
  isMobile: state.app.isMobile,
  applicationsTab: state.app.applicationsTab,
});

const ApplicationsComponent: FC<TApplicationsProps> = ({
  userRole,
  isClientPanelOpen,
  isMobile,
  applicationsTab,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectString = params.get('redirect'); // bar
  const [viewAnchorEl, setViewAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const setIsPanelOpen = (isOpen: boolean) => dispatch(setClientPanel(isOpen));

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    dispatch(setApplicationsTab(newValue));
  };

  const setPanelView = (e: MouseEvent<HTMLLabelElement | SVGSVGElement>, isOpen: boolean) => {
    e.stopPropagation();
    setIsPanelOpen(isOpen);
    setViewAnchorEl(null);
  };
  return (
    <Box className={styles.applications}>
      {redirectString && (
        <Button
          onClick={() => history.push(redirectString)}
          className={clsx('color-4C6AD4', 'text-15', styles['button-back'])}
          startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
        >
          Назад
        </Button>
      )}

      <Box className={styles['applications-top']}>
        <Typography className={clsx('font-golos', 'text-24-medium', 'color-0B1641')}>
          Приложения
        </Typography>
        {isAdministrator(userRole) && (
          <Button
            className={styles['create-button']}
            variant="custom"
            onClick={() => {
              history.push('/applications/create');
            }}
            startIcon={<AddIcon className={styles['start-icon-button']} />}
          >
            Создать приложение
          </Button>
        )}
      </Box>
      <Box className={styles['tabs-wrapper']}>
        <Tabs
          className={styles.tabs}
          TabIndicatorProps={{ className: styles['tab-indicator'] }}
          value={applicationsTab}
          onChange={handleChange}
          variant="scrollable"
        >
          <Tab
            className={styles.tab}
            classes={{ selected: styles['tab-selected'] }}
            label="Разрешения"
            id="0"
          />
          {isAdministrator(userRole) && (
            <Tab
              className={styles.tab}
              classes={{ selected: styles['tab-selected'] }}
              label="Управление"
              id="1"
            />
          )}
          {/* #293
          <Tab
            className={styles.tab}
            classes={{ selected: styles['tab-selected'] }}
            label="Приглашения"
            id="2"
          /> */}
          <Popover
            classes={{ paper: styles['view-popover-paper'] }}
            onClose={() => setViewAnchorEl(null)}
            anchorEl={viewAnchorEl}
            open={!!viewAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <SelectViewType isClientPanelOpen={isClientPanelOpen} setPanelView={setPanelView} />
          </Popover>
          {!isMobile && (
            <Button
              onClick={(event: MouseEvent<HTMLButtonElement>) =>
                setViewAnchorEl(event.currentTarget)
              }
              className={styles.view}
              classes={{ endIcon: styles['view-icon'] }}
              endIcon={<ArrowDown fill="#9DA2B3" />}
            >
              <Typography className={clsx('text-14', 'color-858BA0')}>Вид</Typography>
            </Button>
          )}
        </Tabs>
      </Box>
      <TabPanel value={applicationsTab} index={0}>
        <div className={styles['tab-container']}>
          <ApplicationsTable variant="scopes" />
          <ApplicationRightPanel variant="scopes" />
        </div>
      </TabPanel>
      {isAdministrator(userRole) && (
        <TabPanel value={applicationsTab} index={1}>
          <div className={styles['tab-container']}>
            <ApplicationsTable variant="admin" />
            <ApplicationRightPanel variant="admin" />
          </div>
        </TabPanel>
      )}
      <TabPanel value={applicationsTab} index={2}>
        Item Two
      </TabPanel>
    </Box>
  );
};

export const Applications = connect(mapStateToProps)(ApplicationsComponent);
