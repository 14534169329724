import React, { FC, useState } from 'react';
import styles from './ChangePassword.module.css';
import { Button, Typography } from '@mui/material';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { PasswordTextfield } from '../custom/PasswordTextfield';
import { checkCredentials } from '../../requests/user';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { useChangePasswordMutation } from '../../redux/services/user';
import { validatePassword } from '../../helpers';
import { useGetSettingsQuery } from '../../redux/services/client';

const mapStateToProps = (state: RootState) => ({
  login: state.user.userProfile.login,
  userId: state.user.userProfile.id,
  passwordChangeRequired: state.user.userProfile.password_change_required,
});

type TChangePassword = {
  login?: string;
  userId?: string;
  passwordChangeRequired?: boolean;
};

export const ChangePasswordComponent: FC<TChangePassword> = ({
  login = '',
  userId,
  passwordChangeRequired,
}) => {
  const history = useHistory();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState<null | string>(null);
  const [currentPasswordError, setCurrentPasswordError] = useState<null | string>(null);
  const [repeatPasswordError, setRepeatPasswordError] = useState<null | string>(null);
  const [changePassword] = useChangePasswordMutation();
  const { data: dataSettings } = useGetSettingsQuery();

  const getCurrentPasswordError = () => {
    if (!currentPassword) return setCurrentPasswordError('Укажите текущий пароль');
    if (newPassword === currentPassword) {
      return setNewPasswordError('Текущий и новый пароль не должны совпадать');
    }
  };

  const getNewPasswordError = () => {
    if (!newPassword) return setNewPasswordError('Укажите новый пароль');
    const validationError = validatePassword(newPassword, dataSettings);
    if (validationError) {
      return setNewPasswordError(validationError);
    }
    if (newPassword === currentPassword) {
      return setNewPasswordError('Текущий и новый пароль не должны совпадать');
    }
  };

  const getRepeatPasswordError = () => {
    if (!repeatPassword) return setRepeatPasswordError('Укажите новый пароль');
    if (repeatPassword !== newPassword) return setRepeatPasswordError('Пароли не совпадают');
  };

  const handleChangePassword = async () => {
    if (
      !currentPasswordError &&
      !newPasswordError &&
      !repeatPasswordError &&
      newPassword &&
      repeatPassword &&
      userId
    ) {
      const verified = currentPassword && (await checkCredentials(login, currentPassword));

      if (verified || passwordChangeRequired) {
        await changePassword({ newPassword, userId, revokeTokens: true });
        window.location.reload();
      } else if (verified === false) {
        setCurrentPasswordError('Неправильный текущий пароль');
      }
    }
  };
  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => history.push('/profile')}
        className={clsx('color-4C6AD4', 'text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Профиль
      </Button>
      <div className={styles['change-password']}>
        <Typography
          style={{ marginBottom: 24 }}
          className={clsx('color-0B1641', 'text-24-medium', 'font-golos')}
        >
          Изменить пароль
        </Typography>
        <Typography style={{ marginBottom: 24 }} className={clsx('color-3C4567', 'text-14')}>
          После смены пароля произойдет выход из аккаунта на всех устройствах, где вы вошли с
          текущим паролем.
        </Typography>
        {userId && (
          <>
            {!passwordChangeRequired && (
              <>
                <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
                  Текущий пароль
                </Typography>
                <PasswordTextfield
                  value={currentPassword}
                  onChange={(e) => {
                    if (currentPasswordError) setCurrentPasswordError(null);
                    setCurrentPassword(e.target.value);
                  }}
                  onBlur={getCurrentPasswordError}
                  className={clsx('custom', styles['password-textfield'])}
                  fullWidth
                  variant="standard"
                  FormHelperTextProps={{
                    className: clsx('color-FC545C', 'text-14'),
                  }}
                  error={!!currentPasswordError}
                  helperText={currentPasswordError ? currentPasswordError : ''}
                />
              </>
            )}
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Новый пароль
            </Typography>
            <PasswordTextfield
              value={newPassword}
              onChange={(e) => {
                const { value } = e.target;
                if (newPasswordError) setNewPasswordError(null);
                if (value === repeatPassword && repeatPasswordError === 'Пароли не совпадают')
                  setRepeatPasswordError(null);
                setNewPassword(e.target.value);
              }}
              onBlur={getNewPasswordError}
              className={clsx('custom', styles['password-textfield'])}
              FormHelperTextProps={{
                className: clsx('color-FC545C', 'text-14'),
              }}
              error={!!newPasswordError}
              helperText={newPasswordError ? newPasswordError : ''}
              fullWidth
              variant="standard"
            />
            <Typography style={{ marginBottom: 8 }} className={clsx('color-0B1641', 'text-14')}>
              Повторите новый пароль
            </Typography>
            <PasswordTextfield
              value={repeatPassword}
              onChange={(e) => {
                if (repeatPasswordError) setRepeatPasswordError(null);
                setRepeatPassword(e.target.value);
              }}
              onBlur={getRepeatPasswordError}
              className={clsx('custom', styles['password-textfield'])}
              fullWidth
              variant="standard"
              FormHelperTextProps={{
                className: clsx('color-FC545C', 'text-14'),
              }}
              error={!!repeatPasswordError}
              helperText={repeatPasswordError ? repeatPasswordError : ''}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 48 }}>
              <Button onClick={() => history.goBack()} variant="custom" color="secondary">
                Отмена
              </Button>
              <Button onClick={handleChangePassword} variant="custom" style={{ marginLeft: 24 }}>
                Изменить
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const ChangePassword = connect(mapStateToProps)(ChangePasswordComponent);
