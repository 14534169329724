import React, { FC, MouseEvent } from 'react';
import styles from './SelectViewType.module.css';
import { CustomRadioButton } from '../CustomRadioButton';
import { ReactComponent as WithViewIcon } from '../../icons/WithView.svg';
import { ReactComponent as ListOnlyIcon } from '../../icons/ListOnly.svg';
import Box from '@mui/material/Box';
import clsx from 'clsx';

export const SelectViewType: FC<{
  isClientPanelOpen: boolean;
  setPanelView: (e: MouseEvent<HTMLLabelElement | SVGSVGElement>, isOpen: boolean) => void;
}> = ({ isClientPanelOpen, setPanelView }) => {
  return (
    <div className={styles['radio-group']}>
      <Box className={styles['view-radio']}>
        <CustomRadioButton
          label="С просмотром"
          classes={{ label: clsx('text-14', 'color-0B1641') }}
          className={clsx(styles['radio-button'], 'text-14', 'color-0B1641')}
          checked={!isClientPanelOpen}
          onClick={(e: MouseEvent<HTMLLabelElement>) => setPanelView(e, false)}
        />
        <WithViewIcon
          style={{ cursor: 'pointer' }}
          onClick={(e: MouseEvent<SVGSVGElement>) => setPanelView(e, false)}
        />
      </Box>
      <Box className={styles['view-radio']}>
        <CustomRadioButton
          classes={{ label: clsx('text-14', 'color-0B1641') }}
          label="Только список"
          className={clsx(styles['radio-button'], 'text-14', 'color-0B1641')}
          checked={isClientPanelOpen}
          onClick={(e: MouseEvent<HTMLLabelElement>) => setPanelView(e, true)}
        />
        <ListOnlyIcon
          style={{ cursor: 'pointer' }}
          onClick={(e: MouseEvent<SVGSVGElement>) => setPanelView(e, true)}
        />
      </Box>
    </div>
  );
};
