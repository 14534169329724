import Button from '@mui/material/Button';
import React, { ChangeEvent, FC, ReactElement, useEffect } from 'react';
import { BACKEND_URL } from '../constants';
import styles from './UploadAndDisplayImage.module.css';

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(String(reader.result) || '');
    reader.onerror = (error) => reject(error);
  });

type TUploadAndDisplayImageProps = {
  setAvatarError: (error: string) => void;
  setAvatarValue: (value: File | null) => void;
  clearAvatarError: () => void;
  defaultValue: string | null;
  componentName?: string;
  DefaultIcon?: ReactElement;
  overRideImageSrc?: File | string | null;
  pathToAvatar?: string | null;
  setAvatarLink?: (value: string) => void;
  imgSrc?: string | null;
  setImgSrc: (value: string | null) => void;
  disabled?: boolean;
};

export const UploadAndDisplayImage: FC<TUploadAndDisplayImageProps> = ({
  setAvatarError,
  defaultValue,
  setAvatarValue,
  clearAvatarError,
  componentName,
  DefaultIcon,
  overRideImageSrc,
  pathToAvatar,
  setAvatarLink,
  imgSrc,
  setImgSrc,
  disabled,
}) => {
  useEffect(() => {
    if (typeof pathToAvatar !== 'string') pathToAvatar = null;
    setImgSrc(pathToAvatar);
  }, [pathToAvatar]);

  useEffect(() => {
    if (overRideImageSrc) {
      const setImgSrcAsync = async () => {
        if (overRideImageSrc instanceof Blob) {
          setImgSrc(await toBase64(overRideImageSrc));
        } else {
          setImgSrc(overRideImageSrc);
        }
      };

      setImgSrcAsync();
    }
  }, [overRideImageSrc]);

  useEffect(() => {
    if (defaultValue) {
      setImgSrc(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className={styles.container}>
      <div className={styles['flex-container']}>
        <div
          style={{
            backgroundImage: `url(${
              (imgSrc?.startsWith('data:') ? '' : BACKEND_URL + '/') + imgSrc
            })`,
          }}
          className={styles['app-icon']}
        >
          {!imgSrc && DefaultIcon}
        </div>
        {imgSrc && !disabled &&(
          <Button
            onClick={() => {
              setImgSrc(null);
              setAvatarValue(null);
              clearAvatarError();
              setAvatarLink?.('');
            }}
            className={styles['delete-button']}
            variant="custom"
            color="secondary"
          >
            Удалить
          </Button>
        )}
        <Button variant="custom" component="label" color="secondary"  disabled={disabled}>
          Загрузить
          <input
            id={componentName}
            type="file"
            name="myImage"
            hidden
            onChange={async (event: ChangeEvent<HTMLInputElement>) => {
              const file = event.target.files?.[0];

              if (file) {
                const src = await toBase64(file);
                if (
                  !['jpeg', 'png', 'jpg', 'svg+xml'].find(
                    (imageType) => `image/${imageType}` === file.type,
                  )
                )
                  return setAvatarError('Недопустимый формат изображения');

                if (file.size / 1024 / 1024 > 1)
                  return setAvatarError('Размер изображения не должен превышать 1 МБ');

                setImgSrc(src);
                setAvatarValue(file);
                event.target.value = '';
                clearAvatarError();
                setAvatarLink?.('');
              }
            }}
          />
        </Button>
      </div>
    </div>
  );
};
