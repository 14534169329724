import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Popover from '@mui/material/Popover';
import clsx from 'clsx';
import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './AddProvider.module.css';
import { ReactComponent as AddIcon } from '../icons/Add.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as SearchIcon } from '../icons/Search.svg';
import { ReactComponent as ArrowDownIcon } from '../icons/ArrowDown.svg';
import { ReactComponent as CheckMarkIcon } from '../icons/CheckMark.svg';
import { ReactComponent as IdIcon } from '../icons/Id.svg';
import { ReactComponent as BasketIcon } from '../icons/Basket.svg';
import { ReactComponent as EditIcon } from '../icons/Edit.svg';
import { ReactComponent as CopyIcon } from '../icons/Copy.svg';
import { ReactComponent as EmptyListIcon } from '../icons/EmptyList.svg';
import { ReactComponent as EmptySearchIcon } from '../icons/EmptySearch.svg';
import { ChooseProvider } from './ChooseProvider';
import {
  useActivateProvidersMutation,
  useDeleteProviderMutation,
  useGetProvidersQuery,
  useLazyGetSecretQuery,
  useDeactivateProvidersMutation,
  MiscProviderType,
  TOauthProvider,
  TMiscProvider,
  OauthProviderType,
} from '../redux/services/provider';
import { useParams } from 'react-router-dom';
import { EditProvider } from './EditProvider';
import { BACKEND_URL, CLIENT_ID } from '../constants';
import { getProviderTitleByType } from '../helpers';
import { TApplication } from '../redux/services/client';
import { EditLdapProvider } from './EditLdapProvider';
import { EditEthereumProvider } from './EditEthereumProvider';
import { CustomCheckbox } from './custom/CustomCheckbox';
import { CustomPopoverButton } from './custom/CustomPopoverButton';

type TAddProvider = {
  isOpen: boolean;
  close: () => void;
  clearSelectedProvider: () => void;
  selectedProvider?: TOauthProvider | TMiscProvider;
  role: TApplication['role'];
};

export const AddProvider: FC<TAddProvider> = ({
  isOpen,
  close,
  clearSelectedProvider,
  selectedProvider,
  role,
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [providerToEdit, setProviderToEdit] = useState<TOauthProvider | TMiscProvider | null>(null);
  const [providerToDelete, setProviderToDelete] = useState<TOauthProvider | TMiscProvider | null>(
    null,
  );
  const [providers, setProviders] = useState<
    ((TOauthProvider | TMiscProvider) & { isPublic: boolean })[]
  >([]);
  const [fetchingProviderIds, setFetchingProviderIds] = useState<string[]>([]);
  const [isCreateFormOpen, setIsCreateFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isEditLdapFormOpen, setIsEditLdapFormOpen] = useState(false);
  const [isEditEthereumFormOpen, setIsEditEthereumFormOpen] = useState(false);
  const typeVariants = ['Все', 'Публичные', 'Для приложения'];
  const [selectedTypeVariant, setSelectedTypeVariant] = useState(typeVariants[0]);
  const { clientId } = useParams<{ clientId: string }>();
  const anchorPopover = useRef<HTMLButtonElement>(null);
  const { data: appProviders, isFetching: providersFetching } = useGetProvidersQuery({
    client_id: clientId,
    onlyActive: false,
  });

  const isPublicProvider = (provider: TOauthProvider | TMiscProvider) =>
    provider.client_id === CLIENT_ID && clientId !== CLIENT_ID;

  const filteredProviders = providers.filter((provider) => {
    if (selectedTypeVariant === typeVariants[1] && !provider.is_public) return false;
    if (selectedTypeVariant === typeVariants[2] && provider.is_public) return false;
    return provider.name.toLowerCase().includes(searchValue.toLowerCase());
  });

  const [deleteProvider] = useDeleteProviderMutation();
  const [activateProvider] = useActivateProvidersMutation();
  const [deactivateProvider] = useDeactivateProvidersMutation();
  const [getSecret] = useLazyGetSecretQuery();

  useEffect(() => {   
    if (appProviders) {
      setProviders(
        appProviders.map((provider) => ({
          ...provider,
          isPublic: isPublicProvider(provider),
        })),
      );
    }
  }, [appProviders]);

  useEffect(() => {
    if (!isEditFormOpen) setProviderToEdit(null);
  }, [isEditFormOpen]);

  useEffect(() => {
    selectedProvider && createProviderSettings(selectedProvider);
  }, [selectedProvider]);

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
    setProviderToDelete(null);
  };

  const handleDeleteProvider = async () => {
    if (providerToDelete) {
      setFetchingProviderIds((ids) => [...ids, providerToDelete.id]);

      await deleteProvider({
        client_id: clientId,
        provider_id: providerToDelete.id,
        provider_type: providerToDelete.type,
      });
      setProviderToDelete(null);
      setDeleteModalOpen(false);

      setFetchingProviderIds(fetchingProviderIds.filter((id) => id !== providerToDelete.id));
    }
  };

  const createProviderSettings = async (provider: TOauthProvider | TMiscProvider) => {
    setProviderToEdit(provider);

    switch (provider.type) {
      case MiscProviderType.LDAP:
        return setIsEditLdapFormOpen(true);
      case MiscProviderType.ETHEREUM:
        return setIsEditEthereumFormOpen(true);
      default:
        setIsEditFormOpen(true);
    }
  };

  const handleCopyProvider = async (provider: TOauthProvider | TMiscProvider) => {
    let clientSecret: string | undefined;
    if (provider.type in OauthProviderType) {
      const { data } = await getSecret({
        client_id: clientId,
        provider_id: provider?.id || '',
      });
      clientSecret = data;
    }
    
    await navigator.clipboard.writeText(
      JSON.stringify({
        ...provider,
        ...(clientSecret && { external_client_secret: clientSecret }),
      }),
    );
  };

  const handleDrawerClose = () => {
    clearSelectedProvider();
    close();
  };

  const handleActivateProvider = async (provider: TOauthProvider | TMiscProvider) => {
    setFetchingProviderIds((ids) => [...ids, provider.id]);

    if (!provider.is_active) {
      await activateProvider({
        body: {
          providers: [
            {
              type: provider.type,
              id: String(provider.id),
              client_id: provider.client_id,
            },
          ],
        },
        client_id: clientId,
      });
    } else {
      await deactivateProvider({
        body: {
          providers: [
            {
              type: provider.type,
              id: String(provider.id),
              client_id: provider.client_id,
            },
          ],
        },
        client_id: clientId,
      });
    }

    setFetchingProviderIds(fetchingProviderIds.filter((id) => id !== provider.id));
  };

  const handleActivateAllProviders = async () => {
    setFetchingProviderIds((ids) => [...ids, ...filteredProviders.map((provider) => provider.id)]);

    if (filteredProviders.some((provider) => !provider.is_active)) {
      await activateProvider({
        body: {
          providers: filteredProviders?.map((provider) => {
            return {
              id: String(provider.id),
              type: provider.type,
              client_id: provider.client_id,
            };
          }),
        },
        client_id: clientId,
      });
    } else {
      await deactivateProvider({
        body: {
          providers: filteredProviders?.map((provider) => {
            return {
              id: String(provider.id),
              type: provider.type,
              client_id: provider.client_id,
            };
          }),
        },
        client_id: clientId,
      });
    }

    setFetchingProviderIds(
      fetchingProviderIds.filter((id) => filteredProviders.find((provider) => provider.id !== id)),
    );
  };

  return (
    <>
      <Drawer onClose={() => handleDrawerClose()} open={isOpen} anchor="right" variant="temporary">
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <Typography className={clsx('text-24-medium', 'font-golos', 'color-0B1641')}>
              Добавить способ входа
            </Typography>
            <Button
              onClick={() => setIsCreateFormOpen(true)}
              variant="custom"
              className={clsx('text-14', 'color-0B1641', styles['create-button'])}
              color="secondary"
              startIcon={<AddIcon />}
            >
              Создать
            </Button>
            <IconButton onClick={handleDrawerClose} className={styles['close-button']}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography className={clsx('text-15', 'color-858BA0', styles['input-description'])}>
            Выберите способы входа, которые хотите добавить в приложение:
          </Typography>
          <TextField
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className={clsx(styles.search, 'custom')}
            fullWidth
            variant="standard"
            placeholder="Поиск"
            InputProps={{ startAdornment: <SearchIcon className={styles['search-icon']} /> }}
            inputProps={{ className: styles.input }}
          />
          <div className={styles['types-wrapper']}>
            <CustomCheckbox
              onClick={handleActivateAllProviders}
              disabled={!!fetchingProviderIds.length}
              className={styles.checkbox}
              checked={
                filteredProviders.every((provider) => provider.is_active) &&
                !!filteredProviders.length
              }
            />
            <Button
              ref={anchorPopover}
              className={styles['button-type']}
              onClick={() => setPopoverOpen(true)}
              endIcon={<ArrowDownIcon fill="#B6BAC6" />}
            >
              <Typography className={clsx('text-14', 'color-858BA0')}>Тип:</Typography>
              <Typography
                className={clsx('text-14', 'color-0B1641', styles['button-type-variant-text'])}
              >
                {selectedTypeVariant}
              </Typography>
            </Button>
          </div>
          <Popover
            classes={{ paper: styles.popover }}
            open={popoverOpen}
            anchorEl={anchorPopover.current}
            onClose={() => setPopoverOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {typeVariants.map((variant) => (
              <CustomPopoverButton
                key={variant}
                onClick={() => {
                  setSelectedTypeVariant(variant);
                  setPopoverOpen(false);
                }}
                startIcon={
                  <CheckMarkIcon
                    className={clsx(styles['popover-button-icon'], {
                      [styles['hidden-button-icon']]: variant !== selectedTypeVariant,
                    })}
                  />
                }
              >
                {variant}
              </CustomPopoverButton>
            ))}
          </Popover>
          <div style={{ overflow: 'auto', height: 'calc(100vh - 294px)' }}>
            {!providers.length && (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmptyListIcon />
                <Typography style={{ marginBottom: 8 }} className={clsx('text-17', 'color-858BA0')}>
                  Список пуст
                </Typography>
                <Typography
                  className={clsx('text-14')}
                  style={{ marginBottom: 16, color: '#B6BAC6' }}
                >
                  Добавьте свои способы входа
                </Typography>
                <Button
                  onClick={() => setIsCreateFormOpen(true)}
                  variant="custom"
                  className={clsx('text-14', 'color-0B1641')}
                  color="secondary"
                  startIcon={<AddIcon />}
                >
                  Создать
                </Button>
              </div>
            )}
            {!!providers.length && !filteredProviders.length && (
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <EmptySearchIcon />
                <Typography style={{ marginBottom: 8 }} className={clsx('text-17', 'color-858BA0')}>
                  По вашему запросу ничего не найдено
                </Typography>
              </div>
            )}
            {filteredProviders
              .sort((a, b) => {
                if (a.is_active !== b.is_active) return !a.is_active ? 1 : -1;
                return a.client_id === CLIENT_ID ? 1 : -1;
              })
              .map((provider) => (
                <div
                  className={clsx(styles.provider, {
                    [styles['checked-provider']]: provider.is_active,
                  })}
                  key={provider.id + provider.type}
                >
                  <CustomCheckbox
                    onClick={() => {
                      handleActivateProvider(provider);
                    }}
                    checked={provider.is_active}
                    className={styles['provider-checkbox']}
                    disabled={!!fetchingProviderIds.find((id) => id === provider.id)}
                  />
                  <div
                    style={{
                      backgroundImage: `url(${BACKEND_URL + '/' + provider.avatar})`,
                    }}
                    className={styles['provider-icon-wrapper']}
                  >
                    {!provider.avatar && <IdIcon fill="#CED0D9" className={styles['id-icon']} />}
                  </div>
                  <div className={styles['provider-name-wrapper']}>
                    <Typography
                      className={clsx('text-14', 'color-0B1641', styles['provider-name'])}
                    >
                      {provider.name}
                    </Typography>
                    <Typography className={clsx('text-12', 'color-858BA0')}>
                      {getProviderTitleByType(provider.type)}
                    </Typography>
                  </div>
                  {!isPublicProvider(provider) &&
                    provider.type !== MiscProviderType.EMAIL &&
                    provider.type !== MiscProviderType.CREDENTIALS && (
                      <div className={styles['provider-buttons']}>
                        <Tooltip
                          arrow
                          title={'Удалить'}
                          classes={{
                            tooltip: styles['input-tooltip'],
                            arrow: styles['input-tooltip-arrow'],
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setProviderToDelete(provider);
                              setDeleteModalOpen(true);
                            }}
                            className={styles['provider-button']}
                            disabled={
                              !!fetchingProviderIds.find((id) => id === provider.id) ||
                              providersFetching
                            }
                          >
                            <BasketIcon />
                          </IconButton>
                        </Tooltip>

                        <Tooltip
                          arrow
                          onClick={() => {
                            createProviderSettings(provider);
                          }}
                          title={'Редактировать'}
                          classes={{
                            tooltip: styles['input-tooltip'],
                            arrow: styles['input-tooltip-arrow'],
                          }}
                        >
                          <IconButton className={styles['provider-button']}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          placement="bottom-end"
                          arrow
                          title={'Копировать настройки'}
                          classes={{
                            tooltip: styles['input-tooltip'],
                            arrow: styles['input-tooltip-arrow'],
                          }}
                        >
                          <IconButton
                            onClick={() => handleCopyProvider(provider)}
                            className={styles['provider-button']}
                          >
                            <CopyIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                </div>
              ))}
          </div>
        </div>
      </Drawer>
      <ChooseProvider
        role={role}
        isOpen={isCreateFormOpen}
        close={() => setIsCreateFormOpen(false)}
      />
      <EditProvider
        role={role}
        providerToEdit={providerToEdit as TOauthProvider}
        isOpen={isEditFormOpen}
        close={() => {
          setIsEditFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
      />
      <EditLdapProvider
        role={role}
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditLdapFormOpen}
        close={() => {
          setIsEditLdapFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
      />
      <EditEthereumProvider
        role={role}
        providerToEdit={providerToEdit as TMiscProvider}
        isOpen={isEditEthereumFormOpen}
        close={() => {
          setIsEditEthereumFormOpen(false);
          selectedProvider && handleDrawerClose();
        }}
      />
      <Modal open={deleteModalOpen} onClose={closeDeleteModal}>
        <div className={styles['delete-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Удалить способ входа
            </Typography>
            <IconButton onClick={closeDeleteModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Способ входа будет удален. Если способ входа используется в приложении, пользователи не
            смогут войти в него, используя данный способ входа.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeDeleteModal}>
              Отмена
            </Button>
            <Button
              disabled={!!fetchingProviderIds.find((id) => id === providerToDelete?.id)}
              onClick={handleDeleteProvider}
              variant="custom"
              style={{ marginLeft: 24, background: '#FC545C' }}
            >
              Удалить
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
