import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TLDAPColors } from '../../components/LdapColor';
import { BACKEND_URL } from '../../constants';
import { getObjectKeys } from '../../helpers';
import { getAccessToken } from '../../service/auth';

export enum OauthProviderType {
  GOOGLE = 'GOOGLE',
  YANDEX = 'YANDEX',
  VK = 'VK',
  MAILRU = 'MAILRU',
  CUSTOM = 'CUSTOM',
  KLOUD = 'KLOUD',
}

export enum MiscProviderType {
  LDAP = 'LDAP',
  CREDENTIALS = 'CREDENTIALS',
  EMAIL = 'EMAIL',
  ETHEREUM = 'ETHEREUM',
}

export type TOauthProvider = {
  id: string;
  name: string;
  type: OauthProviderType;
  description?: string;
  avatar?: string | null;
  path_to_avatar?: string;
  external_client_id: string;
  external_client_secret: string;
  authorization_endpoint: string;
  token_endpoint: string;
  is_active: boolean;
  is_public: boolean;
  scopes?: string;
  client_id: string;
  userinfo_endpoint: string;
  issuer: string;
  auto_registration: boolean;
  auth_without_email: boolean;
  password_required: boolean;
  redirect_uri: string;
  mapping?: string;
};

export type LdapParams = {
  url: string;
  base: string;
  domain: string;
  mapping: string;
  search_filter: string;
  admin_login: string;
  admin_password: string;
};

export type TMiscProvider = {
  id: string;
  name: string;
  client_id: string;
  type: MiscProviderType;
  description?: string;
  avatar?: string | null;
  path_to_avatar?: string;
  is_active: boolean;
  is_public: boolean;
  auto_registration: boolean;
  auth_without_email: boolean;
  password_required: boolean;
  params?: LdapParams;
  ldap_colors?: TLDAPColors;
  ldap_title?: string;
  show_ldap_avatar?: boolean;
};

export const providerApi = createApi({
  reducerPath: 'providerApi',
  tagTypes: ['Providers'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/provider/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getProviders: builder.query<
      (TOauthProvider | TMiscProvider)[],
      { client_id: string; onlyActive: boolean }
    >({
      query: ({ client_id, onlyActive }) => `/${client_id}/${onlyActive}`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'Providers' as const,
                id,
              })),
              { type: 'Providers', id: 'LIST' },
            ]
          : [{ type: 'Providers', id: 'LIST' }],
    }),

    getSecret: builder.query<string, { client_id: string; provider_id: string }>({
      query: ({ client_id, provider_id }) => ({
        url: `/get_secret/${client_id}/${provider_id}`,
        responseHandler: (response) => response.text(),
      }),
    }),

    createProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<
          Omit<TOauthProvider, 'avatar'> & {
            avatar?: File | null;
            provider_id: string;
            redirect_uri: string;
          }
        >;
        client_id: string;
      }) => ({
        url: `create/${client_id}`,
        method: 'POST',
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    updateProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<
          Omit<TOauthProvider, 'avatar'> & {
            avatar?: File | null;
            provider_id: string;
            redirect_uri: string;
          }
        >;
        client_id: string;
      }) => ({
        url: `update/${client_id}`,
        method: 'PUT',
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    createEthereumProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<Omit<TMiscProvider, 'avatar'> & { avatar?: File | null }>;
        client_id: string;
      }) => ({
        method: 'POST',
        url: `create_ethereum/${client_id}`,
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    updateEthereumProvider: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: Partial<
          Omit<TMiscProvider, 'avatar'> & { avatar?: File | null; provider_id: string }
        >;
        client_id: string;
      }) => ({
        method: 'PUT',
        url: `update_ethereum/${client_id}`,
        body: providerParamsToFormData(body),
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    activateProviders: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: {
          providers: {
            type: TOauthProvider['type'] | TMiscProvider['type'];
            id: string;
            client_id: string;
          }[];
        };
        client_id: string;
      }) => ({
        url: `activate/${client_id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    deactivateProviders: builder.mutation({
      query: ({
        body,
        client_id,
      }: {
        body: {
          providers: {
            type: TOauthProvider['type'] | TMiscProvider['type'];
            id: string;
            client_id: string;
          }[];
        };
        client_id: string;
      }) => ({
        url: `deactivate/${client_id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),

    deleteProvider: builder.mutation<
      undefined,
      {
        client_id: string;
        provider_id: string;
        provider_type: OauthProviderType | MiscProviderType;
      }
    >({
      query: (body) => {
        return {
          url: `/${body.client_id}/${body.provider_id}/${body.provider_type}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'Providers', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetProvidersQuery,
  useLazyGetSecretQuery,
  useCreateProviderMutation,
  useActivateProvidersMutation,
  useDeactivateProvidersMutation,
  useDeleteProviderMutation,
  useUpdateProviderMutation,
  useCreateEthereumProviderMutation,
  useUpdateEthereumProviderMutation,
} = providerApi;

const providerParamsToFormData = (
  requestParams: Partial<
    Omit<TOauthProvider | TMiscProvider, 'avatar'> & {
      avatar?: File | null;
      provider_id: string;
      redirect_uri: string;
    }
  >,
) => {
  try {
    return getObjectKeys(requestParams).reduce((acc, key) => {
      if (key === 'avatar' && requestParams[key] !== undefined) {
        acc.append(key, requestParams[key] || '');
        return acc;
      }

      acc.append(key, requestParams[key]?.toString() || '');

      return acc;
    }, new FormData());
  } catch (e) {
    console.log('providerParamsToFormData error: ' + (e as Error).message);
  }
};
