import { TCustomUserFieldsParams } from './types';

export const NODE_ENV = window?.env?.['NODE_ENV'] || 'development';
export const WIDGET_URL = window?.env?.['WIDGET_URL'] || 'https://widget.trusted.com:3000';
export const DASHBOARD_URL = window?.env?.['DASHBOARD_URL'] || 'https://local.trusted.com:3001';
export const BACKEND_URL = window?.env?.['BACKEND_URL'] || 'https://local.trusted.com:3005';
export const MANUAL_URL = window?.env?.['MANUAL_URL'] || 'https://docs.trusted.plus';
export const DATA_PROCESSING_POLICY_URL =
  window?.env?.['DATA_PROCESSING_POLICY_URL'] ||
  'https://github.com/DigtLab-QA/policy/raw/master/policy_id_trusted_ru.pdf';
export const PROJECT_NAME = window?.env?.['PROJECT_NAME'] || 'ID.Trusted.Net';
export const LOGO_URL = window?.env?.['LOGO'] || BACKEND_URL + '/public/images/logo.svg';

//auth
export const CLIENT_ID = window?.env?.['CLIENT_ID'] || 'sffFF3KJlk23e19fsdf-asfbgh45j';
export const CLIENT_SECRET = window?.env?.['CLIENT_SECRET'] || 'KFIDjg43g-3gokfdggdfgkdJASDdfJG';
export const REDIRECT_URI = window?.env?.['REDIRECT_URI'] || 'https://local.trusted.com:3001/code';
export const ACCESS_TOKEN_TTL = parseInt(window?.env?.['ACCESS_TOKEN_TTL'] || '1800000', 10); //30min
export const REFRESH_TOKEN_TTL = parseInt(window?.env?.['REFRESH_TOKEN_TTL'] || '86400000 ', 10); //24h
export const TRUSTED_VERSION = window?.env?.['TRUSTED_VERSION'] || 'v2.1.27-alpha.1';
export const COPYRIGHT =
  window?.env?.['COPYRIGHT'] || `© ${new Date().getFullYear()}, Цифровые технологии`;

export const CUSTOM_USER_FIELDS = parseCustomFieldsEnvVar() || ({} as const);

function parseCustomFieldsEnvVar(): TCustomUserFieldsParams | undefined {
  try {
    const envVar = window?.env?.['CUSTOM_USER_FIELDS'];
    return envVar ? JSON.parse(envVar) : undefined;
  } catch (e) {
    console.log('parseCustomFieldsEnvVar error: ', e);
  }
}
